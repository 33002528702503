import type * as React from 'react';
export const Macavity2Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FFE3AF" d="M0 0h48v48H0z" />
    <path
      fill="#EFECFC"
      d="M43 48H6c1.5057-7.3806 9.2153-13 18.5-13 9.2846 0 16.9943 5.6194 18.5 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5272-8.0394-9.8006-14-19.5-14S6.5272 39.9606 5 48h1.531c1.522-6.9838 8.8677-12.4526 17.969-12.4526 9.1011 0 16.447 5.4688 17.9689 12.4526H44Z"
    />
    <path
      fill="#FFDC9B"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 38c7.1797 0 13-5.8203 13-13s-5.8203-13-13-13-13 5.8203-13 13 5.8203 13 13 13Z"
    />
    <path
      fill="#BDB1F5"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M38 34.75h.5856l.142-.5681c.5133-2.0533 1.0224-6.1145 1.0224-7.7726C39.75 16.9973 32.7605 9.25 24 9.25S8.25 16.9973 8.25 26.4093c0 1.6581.509 5.7193 1.0224 7.7726l.142.5681h5.8032l.0317-.7169.4577-10.3465a.25.25 0 0 1 .2497-.2389h16.0866a.25.25 0 0 1 .2497.2389l.4577 10.3465.0317.7169H38Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26 32.1448C24.6829 33.4 22.8293 33.2069 22 32M29 28.5979C29 27.7154 28.3284 27 27.5 27s-1.5.7154-1.5 1.5979M22 28.5979C22 27.7154 21.3284 27 20.5 27s-1.5.7154-1.5 1.5979"
    />
    <path
      fill="#1D1D1D"
      d="M26.75 18a.75.75 0 0 0-1.5 0h1.5Zm-1.5 0v5h1.5v-5h-1.5ZM22.75 19a.75.75 0 0 0-1.5 0h1.5Zm-1.5 0v4h1.5v-4h-1.5Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M15.75 21a.75.75 0 0 1 .75.75L16 34h-1.5l.5-12.25a.75.75 0 0 1 .75-.75ZM32.25 21a.75.75 0 0 1 .75.75L33.5 34H32l-.5-12.25a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
