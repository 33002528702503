import type * as React from 'react';
export const Macavity3Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FFE3AF" d="M0 0h48v48H0z" />
    <path
      fill="#fff"
      d="M35.3347 32.0864 30 35.1348l1.4395 10.1056 4.9114 2.625s.2654.0524.6903.1346h6.6895l2.903-3.8261c.03 0-.4551-.1462-1.094-.3388-1.1639-.3507-2.838-.8552-2.838-.9099l1.3549-3.0484c.254-.4234-.0847-.9315-.5928-.9315l-2.9637-.0847c-.4234 0-.7621-.3387-.6774-.7621l.3387-2.2016c.0847-.4234-.3387-.8468-.7621-.7621l-2.4557.4234c-.4234.0847-.7621-.254-.7621-.6774l.1694-2.2017c0-.508-.5081-.8467-1.0162-.5927Z"
    />
    <path
      fill="#1D1D1D"
      d="m30.7442 35.786 5.2169-2.9811-.1575 2.0471v.0288c0 .7987.6778 1.6018 1.6479 1.415l2.3234-.4006-.3192 2.0753c-.1801.9608.6114 1.633 1.4038 1.6401l2.8308.0809-1.3665 3.0745v.1592a.7546.7546 0 0 0 .2352.5474.8027.8027 0 0 0 .1327.102c.0499.0308.0967.0523.1205.063a2.733 2.733 0 0 0 .1775.0703c.1232.0449.2906.1008.4802.1619.3819.1232.8868.2783 1.3839.4291.2885.0876.58.1755.8441.2551l.0635.0191-2.6 3.4269h1.8829l2.559-3.3728-.0105-.008c.0594-.0879.1186-.1953.144-.2724.0187-.1238 0-.3468-.0293-.4417a.7506.7506 0 0 0-.2095-.2989l-.0011-.001c-.0687-.0592-.134-.0926-.146-.0988l-.0012-.0006a.8399.8399 0 0 0-.0509-.0241 1.1467 1.1467 0 0 0-.0785-.0299 5.7536 5.7536 0 0 0-.1341-.0436 47.2889 47.2889 0 0 0-.4416-.1353c-.155-.047-.33-.0997-.5177-.1563l-.0012-.0004a408.5086 408.5086 0 0 1-.8363-.2526 102.784 102.784 0 0 1-1.2024-.3713l1.0119-2.2767c.2555-.4652.1992-.9839-.0511-1.3708-.2513-.3884-.6938-.6453-1.1993-.6494l-2.8971-.0828.3213-2.0884c.0917-.4965-.1143-.9441-.3994-1.2292-.2866-.2866-.7375-.4933-1.2372-.3979l-2.3239.4006.1608-2.0898v-.0288c0-1.0751-1.1034-1.7626-2.1016-1.2635l-.0186.0093L30 34.4836l.7442 1.3024Z"
    />
    <path
      fill="#80DBC1"
      d="M43 48H6c1.5057-7.3806 9.2153-13 18.5-13 9.2846 0 16.9943 5.6194 18.5 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5272-8.0394-9.8006-14-19.5-14S6.5272 39.9606 5 48h1.531c1.522-6.9838 8.8677-12.4526 17.969-12.4526 9.1011 0 16.447 5.4688 17.9689 12.4526H44Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.9993 40.0655c8.2906 0 15.0115-6.6509 15.0115-14.8551 0-8.2043-6.7209-14.8552-15.0115-14.8552S8.9878 17.0061 8.9878 25.2104c0 8.2042 6.7209 14.8551 15.0115 14.8551Z"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.9989 37.4617c4.837 0 8.7581-4.2042 8.7581-9.3902 0-5.1861-3.9211-9.3902-8.7581-9.3902-4.837 0-8.7582 4.2041-8.7582 9.3902 0 5.186 3.9212 9.3902 8.7582 9.3902Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5466 32.0491c-1.5747 1.5164-3.791 1.2831-4.7824-.175M18.7676 25.776l2.8928 2.3934-2.8928 1.9069M29.543 25.776l-2.8929 2.3934 2.8929 1.9069"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m20 13.738 1.0395-6.7185c.1039-.6715.8328-1.046 1.4392-.7397l2.938 1.4845 2.0588-1.5604c.5888-.4463 1.4415-.122 1.585.6028l.4797 2.424 4.176-.7593c.6474-.1177 1.2301.4111 1.1754 1.0669V15S31.5 12.1075 30 11.5537C28.5 11 27.2054 10.4304 24.5 11c-1.5.3158-3.9444 1.7555-4.5 2.738Z"
    />
    <path
      fill="#000"
      d="M27.866 15.232c.4783-.2761.6422-.8877.366-1.366-.2761-.4783-.8877-.6422-1.366-.366-.4783.2761-.6421.8877-.366 1.366.2762.4783.8878.6422 1.366.366ZM30.866 18.232c.4783-.2761.6422-.8877.366-1.366-.2761-.4783-.8877-.6422-1.366-.366-.4783.2761-.6421.8877-.366 1.366.2762.4783.8878.6422 1.366.366ZM16.866 18.232c.4783-.2761.6422-.8877.366-1.366-.2761-.4783-.8877-.6422-1.366-.366-.4783.2761-.6421.8877-.366 1.366.2762.4783.8878.6422 1.366.366ZM14.866 44.232c.4783-.2761.6422-.8877.366-1.366-.2761-.4783-.8877-.6422-1.366-.366-.4783.2761-.6421.8877-.366 1.366.2762.4783.8878.6422 1.366.366ZM34.866 42.232c.4783-.2761.6422-.8877.366-1.366-.2761-.4783-.8877-.6422-1.366-.366-.4783.2761-.6421.8877-.366 1.366.2762.4783.8878.6422 1.366.366ZM28.866 45.232c.4783-.2761.6422-.8877.366-1.366-.2761-.4783-.8877-.6422-1.366-.366-.4783.2761-.6421.8877-.366 1.366.2762.4783.8878.6422 1.366.366Z"
      opacity={0.2}
    />
  </svg>
);
