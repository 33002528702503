import type * as React from 'react';
export const Barry1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EFD7F6" d="M0 0h48v48H0z" />
    <circle
      cx={12}
      cy={29}
      r={3.25}
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <circle
      cx={36}
      cy={29}
      r={3.25}
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <path
      fill="#BFEDE0"
      d="M43 48H6c1.5057-7.3806 9.2153-13 18.5-13 9.2846 0 16.9943 5.6194 18.5 13Z"
    />
    <path
      fill="#1D1D1D"
      d="M44 48c-1.5272-8.0394-9.8006-14-19.5-14S6.5272 39.9606 5 48h1.531c1.522-6.9838 8.8677-12.4526 17.969-12.4526 9.1011 0 16.447 5.4688 17.9689 12.4526H44Z"
    />
    <path
      fill="#E1AB87"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 39c6.6274 0 12-5.3726 12-12s-5.3726-12-12-12-12 5.3726-12 12 5.3726 12 12 12Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M30.274 28.637c0-.9041-.7329-1.637-1.637-1.637S27 27.7329 27 28.637M21.274 28.637c0-.9041-.7329-1.637-1.637-1.637S18 27.7329 18 28.637"
    />
    <path
      fill="#1D1D1D"
      d="M21.0193 32.2269c.0157 1.6328 1.3442 2.9516 2.9807 2.9516 1.6366 0 2.9651-1.3188 2.9808-2.9516h-5.9615Z"
    />
    <path
      fill="#4DCCA8"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.0687 15.5142c-2.1666.5237-4.249 1.9755-5.4481 4.1069-1.199 2.1314-1.3119 4.4109-.404 6.1167 2.1666-.5237 4.249-1.9755 5.448-4.1069 1.2252-2.0953 1.2691-4.3799.4041-6.1167ZM31.2812 15.5271c2.0928.4406 4.0727 1.8102 5.1695 3.8912s1.1307 4.3508.1895 6.0869c-2.0928-.4405-4.0727-1.8102-5.1695-3.8912-1.1234-2.044-1.09-4.3215-.1895-6.0869Z"
    />
    <path
      fill="#4DCCA8"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M25 11.6969c.1183 2.4845 1.4194 4.8536 3.6667 6.1825 2.3064 1.3868 5.0268 1.4446 7.3333.4045-.1183-2.4846-1.4194-4.8536-3.6667-6.1826-2.3064-1.3289-5.0268-1.4445-7.3333-.4044Z"
    />
    <path
      fill="#4DCCA8"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.1513 12c-2.4643 1.1224-4.0639 3.098-4.1504 5.3878-.0432 2.2898 1.4699 4.3102 3.8478 5.6122 2.4211-1.1673 4.0639-3.098 4.1504-5.3878.0432-2.2898-1.4699-4.3102-3.8478-5.6122Z"
    />
    <path
      fill="#4DCCA8"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M25 11.8295c-2.4975-1.1053-5.5714-1.1635-8.2611.1745-2.6896 1.338-4.4187 3.665-4.7389 6.1665 2.4975 1.1053 5.5714 1.1635 8.2611-.1745 2.6896-1.2799 4.3547-3.665 4.7389-6.1665Z"
    />
  </svg>
);
