import type * as React from 'react';
export const Bloomberg3Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#BFEDE0" d="M0 0h48v48H0z" />
    <path
      fill="#FEEFED"
      d="M42.271 48H4.7895c2.8374-7.7313 10.1578-13.2364 18.7408-13.2364S39.4335 40.2687 42.271 48Z"
    />
    <path
      fill="#1D1D1D"
      d="M43.0793 48c-2.8719-8.1615-10.5361-14-19.5396-14C14.5361 34 6.872 39.8385 4 48h1.602c2.8002-7.3008 9.7757-12.4727 17.9377-12.4727 8.162 0 15.1374 5.1719 17.9377 12.4727h1.6019Z"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24 40c6.6274 0 12-5.3726 12-12s-5.3726-12-12-12-12 5.3726-12 12 5.3726 12 12 12Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M29.274 28.637c0-.9041-.7329-1.637-1.637-1.637S26 27.7329 26 28.637M21.274 28.637c0-.9041-.7329-1.637-1.637-1.637S18 27.7329 18 28.637"
    />
    <path
      fill="#1D1D1D"
      d="M21 32c.0148 1.6328 1.2618 2.9516 2.798 2.9516S26.5813 33.6328 26.5961 32H21Z"
    />
    <path
      fill="#F9B0A3"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="m32.7937 16.7482-.3751.6495.3751-.6495-.055-.0317c-.1666-.0962-.1666-.3368 0-.433l-.375-.6495.375.6495.055-.0317c1.519-.8772.9466-3.195-.8061-3.2642l-1.0242-.0404a.25.25 0 0 1-.2244-.3372l.1723-.4616c.5454-1.4608-.948-2.8517-2.3665-2.2039l-1.8892.8628a.2498.2498 0 0 1-.3231-.1074l-.7973-1.457c-.6639-1.2131-2.4065-1.2131-3.0704 0l-.7973 1.457a.2498.2498 0 0 1-.3231.1074l-1.8892-.8628c-1.4185-.6478-2.9119.7431-2.3665 2.2039l.7026-.2623-.7026.2623.1723.4616a.25.25 0 0 1-.2244.3372l-1.0242.0404c-1.7527.0692-2.3251 2.387-.8061 3.2642l.055.0317c.1666.0962.1666.3368 0 .433l-.055.0317c-1.519.8772-.9466 3.195.8061 3.2642l1.0242.0404a.25.25 0 0 1 .2244.3372l-.1723.4616c-.5454 1.4608.948 2.8517 2.3665 2.2039l1.8892-.8628a.2498.2498 0 0 1 .3231.1074l.7973 1.4569c.6639 1.2132 2.4064 1.2132 3.0704 0l.7973-1.4569a.2498.2498 0 0 1 .3231-.1074l1.8892.8628c1.4185.6478 2.9119-.7431 2.3665-2.2039l-.1723-.4616a.25.25 0 0 1 .2244-.3372l1.0242-.0404c1.7527-.0692 2.3251-2.387.8061-3.2642Z"
    />
    <path
      fill="#F7907E"
      d="M18.0177 34.0354c1.1143 0 2.0177-.9034 2.0177-2.0177S19.132 30 18.0177 30 16 30.9034 16 32.0177s.9034 2.0177 2.0177 2.0177ZM30.0177 34.0354c1.1143 0 2.0177-.9034 2.0177-2.0177S31.132 30 30.0177 30 28 30.9034 28 32.0177s.9034 2.0177 2.0177 2.0177Z"
      opacity={0.7}
    />
  </svg>
);
