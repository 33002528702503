import type * as React from 'react';
export const Fruity3Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#BFEDE0" d="M0 0h48v48H0z" />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.1556 18.1281c-.6837 2.9929.2124 8.3366 7.6608 8.221.5329-3.0121-.5743-8.3827-7.6608-8.221ZM38.7104 18.1281c.6837 2.9929-.2124 8.3366-7.6608 8.221-.5329-3.0121.5743-8.3827 7.6608-8.221Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M33.175 12.5559c1.2791 2.7908 1.4905 8.2049-5.8252 9.6094-1.1354-2.8404-1.1457-8.3239 5.8252-9.6094ZM14.4713 12.5559c-1.2791 2.7908-1.4906 8.2049 5.8252 9.6094 1.1354-2.8404 1.1456-8.3239-5.8252-9.6094Z"
    />
    <path
      fill="#FEEFED"
      d="M40.2829 48H6.7773c3.5705-5.5615 9.7402-9.2363 16.7528-9.2363 7.0126 0 13.1823 3.6748 16.7528 9.2363Z"
    />
    <path
      fill="#1D1D1D"
      d="M7.793 48H6c3.6599-6.0029 10.1926-10 17.6422-10 7.4497 0 13.9824 3.9971 17.6423 10h-1.793c-3.4732-5.1188-9.2765-8.4727-15.8493-8.4727-6.5727 0-12.3761 3.3539-15.8492 8.4727Z"
    />
    <circle
      cx={24}
      cy={28.5}
      r={12.5}
      fill="#F9B0A3"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    />
    <path
      fill="#1D1D1D"
      d="M28.9965 36.4053c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177ZM18.6205 36.4053c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177Z"
      opacity={0.06}
    />
    <path
      fill="#1D1D1D"
      d="M18.6205 32.5304c-.7392 0-1.3384-.6388-1.3384-1.4268s.5992-1.4268 1.3384-1.4268c.7392 0 1.3385.6388 1.3385 1.4268s-.5993 1.4268-1.3385 1.4268ZM28.9965 32.5314c-.7392 0-1.3384-.6388-1.3384-1.4268 0-.7881.5992-1.4269 1.3384-1.4269.7392 0 1.3385.6388 1.3385 1.4269 0 .788-.5993 1.4268-1.3385 1.4268ZM26.9854 34.832c-.0168 1.7406-1.433 3.1464-3.1775 3.1464-1.7445 0-3.1607-1.4058-3.1775-3.1464h6.355Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.1513 10c-2.4643 1.1224-4.0639 3.098-4.1504 5.3878-.0432 2.2898 1.4699 4.3102 3.8478 5.6122 2.4211-1.1673 4.0639-3.098 4.1504-5.3878.0432-2.2898-1.4699-4.3102-3.8478-5.6122Z"
    />
    <circle cx={17} cy={24.348} r={1} fill="#F7907E" />
    <circle cx={20.182} cy={22} r={1} fill="#F7907E" />
    <circle cx={20.586} cy={25.52} r={1} fill="#F7907E" />
  </svg>
);
