import type * as React from 'react';
export const Whiz1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <path
      fill="#F7907E"
      d="M45.9922 48h-44c1.586-10.7502 10.8317-19 22-19s20.4139 8.2498 22 19Z"
    />
    <path
      fill="#1D1D1D"
      d="M1.0487 47.6329C2.7034 36.5257 12.3485 28 24.0034 28s21.3 8.5257 22.9547 19.6329l.0419.3633h-1.4864l-.1993-1.0036c-1.9033-9.9619-10.7225-17.4932-21.3109-17.4932S4.5958 37.0307 2.6924 46.9926L2.5214 48H1l.0487-.3671Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m16.3434 36.8878.3889-7.1709c.0099-.1832.2072-.2918.3685-.2028l6.6199 3.6534c.1769.0976.1738.3527-.0053.4426l-7.0089 3.5175c-.1716.0861-.3736-.0473-.3631-.2398Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m30.0263 37.6912.3918-7.1707c.0101-.1832-.1741-.3161-.3439-.2482l-6.9707 2.7889c-.1863.0745-.2109.3283-.0427.4403l6.5788 4.3818c.1611.1072.3762.0004.3867-.1921Z"
    />
    <path
      fill="#E1AB87"
      d="M24.1615 33.5634c6.9685 0 12.6176-5.6491 12.6176-12.6175 0-6.9685-5.6491-12.6176-12.6176-12.6176S11.5439 13.9774 11.5439 20.946c0 6.9684 5.6491 12.6175 12.6176 12.6175Z"
    />
    <path
      fill="#F7907E"
      d="M15.8068 25.9345c.8057.2309 1.6461-.2352 1.877-1.0409.2309-.8058-.2352-1.6462-1.041-1.8771-.8057-.2308-1.6461.2352-1.877 1.041-.2309.8058.2352 1.6461 1.041 1.877ZM27.6197 29.7406c.8058.2308 1.6462-.2352 1.8771-1.041.2308-.8058-.2352-1.6461-1.041-1.877-.8058-.2309-1.6461.2352-1.877 1.0409-.2309.8058.2352 1.6462 1.0409 1.8771Z"
      opacity={0.7}
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18.7821 27.048c1.1496 2.0006 3.7034 2.6905 5.704 1.5409M28.3255 25.5087c.3725-1.07-.1928-2.2393-1.2628-2.6118-1.0699-.3725-2.2392.1929-2.6117 1.2628M21.3599 23.1097c.3725-1.07-.1928-2.2393-1.2627-2.6118-1.0699-.3725-2.2393.1929-2.6118 1.2628"
    />
    <path
      fill="#1D1D1D"
      fillOpacity={0.7}
      d="M20.815 16.7805c-4.7794-1.7573-6.7151-1.6794-6.9908-3.0763 1.6563-1.3682 6.9673-6.4867 12.0524-5.1648 2.2066.5736 7.498 3.525 8.8207 6.5631 4.4551 7.3827-1.223 15.4607-.7827 13.4186.5749-2.6664 1.5953-3.9911-.1457-6.7977-1.2265-1.9772-5.8252-3.5141-9.1385-4.3361l-.5053-2.6052c-.6743-.1042-2.6231 2.0546-3.3101 1.9984Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.0154 13.8873 7.5227 2.4899 2.3534-1.5209.6678 2.4079 1.7823.583c4.568 1.4119 9.7915 4.0613 8.3459 9.2778l-.3438 1.2812"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M24.1615 33.5634c6.9685 0 12.6176-5.6491 12.6176-12.6176S31.13 8.3283 24.1615 8.3283s-12.6176 5.649-12.6176 12.6175 5.6491 12.6176 12.6176 12.6176Z"
    />
  </svg>
);
