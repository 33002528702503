import type * as React from 'react';
export const Birdy2Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FFE3AF" d="M0 0h48v48H0z" />
    <path
      fill="#DED8FA"
      d="M44.2914 47.9276H4.0561c.7767-10.4322 9.4869-18.6549 20.1177-18.6549 10.6307 0 19.3409 8.2227 20.1176 18.6549Z"
    />
    <path
      fill="#1D1D1D"
      d="M44.7393 47.9276c-.7782-10.8469-9.8245-19.4049-20.8697-19.4049-11.0451 0-20.0914 8.558-20.8696 19.4049h1.5042c.775-10.0176 9.1491-17.9049 19.3654-17.9049 10.2164 0 18.5905 7.8873 19.3655 17.9049h1.5042Z"
    />
    <path
      fill="#EBCDF4"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M37.4683 22.7433c0 7.6189-5.9569 13.75-13.25 13.75-7.2932 0-13.25-6.1311-13.25-13.75 0-7.619 5.9568-13.75 13.25-13.75 7.2931 0 13.25 6.131 13.25 13.75Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.7947 23.7486c.1104-.7827-.3212-1.5403-1.0723-1.8086-.8915-.3261-1.879.095-2.2012.9974l-.1857.4618M16.2032 23.4314c-.1361-.77.2908-1.5579 1.0313-1.8524.8677-.3864 1.85.047 2.2317.9254l.1801.464"
    />
    <circle
      cx={22.352}
      cy={43.214}
      r={1.173}
      fill="#F7F5FE"
      transform="rotate(-78.926 22.352 43.214)"
    />
    <circle
      cx={20.659}
      cy={39.64}
      r={1.173}
      fill="#F7F5FE"
      transform="rotate(-78.926 20.66 39.64)"
    />
    <circle
      cx={24.191}
      cy={39.92}
      r={1.173}
      fill="#F7F5FE"
      transform="rotate(-78.926 24.191 39.92)"
    />
    <path
      fill="#8C77EE"
      d="M27.5 16.2006c5.0699-.4763 8.353 1.2685 8.353 1.2685-.353-2.5416-5.5988-8.135-10.8529-8.1577-2.2799-.01-8.9442 1.9012-11 4.4999-6.1953 5.9975-2.5967 14.5869-2.5 12.5001.1262-2.7248.099-4.7322 2.5-7.0001 1.6914-1.5977 6.4318-3.1635 9.8452-3.1107l1.1548-2.3893c.6785.0718 2.4999 2.3893 2.4999 2.3893Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m35.9126 17.2311-9.0549-1.3594-1.8935-2.118-1.2855 2.2219-1.8911.1206c-4.3334.2041-9.1963 2.5533-10.1177 7.0557-.2159 1.0549-.3282 2.1382-.6679 3.16"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M37.25 22.8113c0 7.6189-5.9568 13.75-13.25 13.75s-13.25-6.1311-13.25-13.75c0-7.619 5.9568-13.75 13.25-13.75s13.25 6.131 13.25 13.75Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M27 26.2171c-1.9756 2.5103-4.7561 2.1241-6-.2896"
    />
    <ellipse cx={15.887} cy={27.681} fill="#F7907E" rx={1.887} ry={1.754} />
    <ellipse cx={31.862} cy={27.681} fill="#F7907E" rx={1.887} ry={1.754} />
  </svg>
);
