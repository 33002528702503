import type * as React from 'react';
export const Fruity2Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FCD7D1" d="M0 0h48v48H0z" />
    <circle
      cx={11}
      cy={27}
      r={3.25}
      fill="#FFDC9B"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <circle
      cx={37}
      cy={27}
      r={3.25}
      fill="#FFDC9B"
      stroke="#1D1D1D"
      strokeWidth={1.5}
    />
    <path
      fill="#80DBC1"
      d="M40 48H7c3.5166-7.2256 9.5932-12 16.5-12 6.9068 0 12.9834 4.7744 16.5 12Z"
    />
    <path
      fill="#1D1D1D"
      d="M7.7786 48H6c3.6303-7.2034 10.1104-12 17.5-12S37.3697 40.7966 41 48h-1.7786C35.7763 41.8575 30.0198 37.8327 23.5 37.8327S11.2237 41.8575 7.7786 48Z"
    />
    <ellipse
      cx={24}
      cy={25}
      fill="#FFDC9B"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      rx={12}
      ry={13}
    />
    <path
      fill="#1D1D1D"
      d="M28.9965 33.4053c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177ZM18.6205 33.4053c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177Z"
      opacity={0.08}
    />
    <path
      fill="#1D1D1D"
      d="M20 28c-.5523 0-1-.6716-1-1.5s.4477-1.5 1-1.5 1 .6716 1 1.5-.4477 1.5-1 1.5ZM28 28c-.5523 0-1-.6716-1-1.5s.4477-1.5 1-1.5 1 .6716 1 1.5-.4477 1.5-1 1.5ZM27 30c-.0158 1.6596-1.3529 3-3 3s-2.9842-1.3404-3-3h6Z"
    />
    <path
      fill="#1D1D1D"
      d="M37.0638 48h-1.5135c.4649-3.4507 1.0823-8.1138 1.2344-9.6481.4766-4.8085.2821-10.1374-.0001-12.8519-.2821-2.7145-1.2779-7.1322-2.9321-9.5025-1.9282-2.7628-5.1943-5.4238-8.9301-4.9403-.4454.0577-.4299 1.496-.4188 2.5256.0016.1495.0031.2904.0031.4172 0 1.3463 1.5 3.5 4.5 6 .6595.5495 1.2381.9559 1.7478 1.3138.9747.6845 1.6976 1.1921 2.2522 2.1862 1.6343 2.9292 1.2488 7.3498.9884 10.3358-.0755.8658-.1404 1.6109-.1426 2.1642-.0063 1.5898-.5568 7.8007-.9458 12h-1.5065c.0912-.9828.1919-2.0823.2926-3.2104.3334-3.7323.6551-7.6465.6597-8.7955.0024-.6205.0735-1.434.1475-2.2794l.0265-.3043c.0856-.9893.1733-2.0789.1976-3.1991.0498-2.2958-.1791-4.4602-1.0273-5.9805-.277-.4964-.611-.8211-1.1807-1.2439-.1554-.1154-.3437-.2478-.5568-.3976-.5268-.3703-1.2051-.8471-1.9129-1.437-1.5601-1.3001-2.7796-2.55-3.6228-3.6959-.8062-1.0955-1.4169-2.2945-1.4169-3.4564 0-.0972-.0013-.2161-.0028-.3497-.0049-.4464-.0116-1.057.0227-1.5717.0221-.3313.0667-.7473.1842-1.1225.0582-.1859.1626-.4567.3621-.714.2147-.2766.5943-.5996 1.1569-.6725 4.5782-.5925 8.3146 2.649 10.3527 5.5694.9692 1.3886 1.6865 3.2741 2.1942 5.0733.5147 1.8242.8501 3.6917.9999 5.1326.2908 2.7979.489 8.2298.0008 13.155-.129 1.3013-.5779 4.7448-.9893 7.8267-.0766.5739-.1521 1.137-.2243 1.6734Z"
    />
    <path
      fill="#FFF1D7"
      d="M33.8525 15.9975c-1.9282-2.7628-5.1943-5.4238-8.9301-4.9403-.4454.0577-.4299 1.496-.4188 2.5256.0016.1495.0031.2904.0031.4172 0 1.3463 1.5 3.5 4.5 6 .6595.5495 1.2381.9559 1.7478 1.3138.9747.6845 1.6976 1.1921 2.2522 2.1862 1.6343 2.9292 1.2488 7.3498.9884 10.3358-.0755.8658-.1404 1.6109-.1426 2.1642-.0063 1.5898-.5568 7.8007-.9458 12h2.6436c.4649-3.4507 1.0823-8.1138 1.2344-9.6481.4766-4.8085.2821-10.1374-.0001-12.8519-.2821-2.7145-1.2779-7.1322-2.9321-9.5025Z"
    />
    <path
      fill="#1D1D1D"
      d="M10.943 48h1.5136c-.4649-3.4507-1.0824-8.1138-1.2344-9.6481-.4767-4.8085-.2821-10.1374 0-12.8519.2822-2.7145 1.2779-7.1322 2.9322-9.5025 1.9281-2.7628 5.1942-5.4238 8.9301-4.9403.4453.0577.4298 1.496.4187 2.5256a40.4709 40.4709 0 0 0-.0031.4172c0 1.3463-1.5 3.5-4.5 6-.6594.5495-1.238.9559-1.7478 1.3138-.9747.6845-1.6975 1.1921-2.2522 2.1862-1.6343 2.9292-1.2488 7.3498-.9884 10.3358.0755.8658.1405 1.6109.1427 2.1642.0062 1.5898.5567 7.8007.9458 12h1.5064a953.7819 953.7819 0 0 1-.2926-3.2104c-.3333-3.7323-.6551-7.6465-.6596-8.7955-.0025-.6205-.0736-1.434-.1475-2.2794l-.0265-.3043c-.0857-.9893-.1734-2.0789-.1977-3.1991-.0498-2.2958.1791-4.4602 1.0273-5.9805.277-.4964.611-.8211 1.1807-1.2439.1555-.1154.3438-.2478.5568-.3976.5269-.3703 1.2051-.8471 1.9129-1.437 1.5601-1.3001 2.7796-2.55 3.6229-3.6959.8061-1.0955 1.4168-2.2945 1.4168-3.4564 0-.0972.0013-.2161.0028-.3497.0049-.4464.0116-1.057-.0227-1.5717-.022-.3313-.0667-.7473-.1841-1.1225-.0582-.1859-.1626-.4567-.3622-.714-.2146-.2766-.5943-.5996-1.1569-.6725-4.5781-.5925-8.3146 2.649-10.3527 5.5694-.9691 1.3886-1.6865 3.2741-2.1942 5.0733-.5147 1.8242-.85 3.6917-.9999 5.1326-.2908 2.7979-.489 8.2298-.0007 13.155.129 1.3013.5779 4.7448.9892 7.8267.0766.5739.1521 1.137.2243 1.6734Z"
    />
    <path
      fill="#FFF1D7"
      d="M14.1544 15.9975c1.9281-2.7628 5.1942-5.4238 8.9301-4.9403.4453.0577.4298 1.496.4188 2.5256a37.9631 37.9631 0 0 0-.0032.4172c0 1.3463-1.5 3.5-4.5 6-.6594.5495-1.238.9559-1.7477 1.3138-.9748.6845-1.6976 1.1921-2.2523 2.1862-1.6343 2.9292-1.2488 7.3498-.9884 10.3358.0755.8658.1405 1.6109.1427 2.1642.0063 1.5898.5567 7.8007.9458 12h-2.6436c-.4649-3.4507-1.0824-8.1138-1.2344-9.6481-.4767-4.8085-.2821-10.1374 0-12.8519.2822-2.7145 1.2779-7.1322 2.9322-9.5025Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M37 22.5H11V21h26v1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
