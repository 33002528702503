import type * as React from 'react';
export const Fruity1Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EFD7F6" d="M0 0h48v48H0z" />
    <path
      fill="#FBC0B5"
      d="M40.2829 48H6.7773c3.5705-5.5615 9.7402-9.2363 16.7528-9.2363 7.0126 0 13.1823 3.6748 16.7528 9.2363Z"
    />
    <path
      fill="#1D1D1D"
      d="M7.793 48H6c3.6599-6.0029 10.1926-10 17.6422-10 7.4497 0 13.9824 3.9971 17.6423 10h-1.793c-3.4732-5.1188-9.2765-8.4727-15.8493-8.4727-6.5727 0-12.3761 3.3539-15.8492 8.4727Z"
    />
    <circle cx={24} cy={28.5} r={12.5} fill="#fff" />
    <circle cx={24} cy={28.5} r={12.5} fill="#FFB938" fillOpacity={0.7} />
    <circle
      cx={24}
      cy={28.5}
      r={12.5}
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    />
    <path
      fill="#1D1D1D"
      d="M28.9965 36.4053c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177ZM18.6205 36.4053c1.1143 0 2.0177-.9034 2.0177-2.0177 0-1.1144-.9034-2.0177-2.0177-2.0177-1.1144 0-2.0177.9033-2.0177 2.0177 0 1.1143.9033 2.0177 2.0177 2.0177Z"
      opacity={0.04}
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M25.0329 18.5c-.2103-1.7257.4887-5.5417 4.9671-7"
    />
    <circle cx={17.662} cy={22.732} r={1.173} fill="#fff" opacity={0.4} />
    <circle cx={20.844} cy={20.384} r={1.173} fill="#fff" opacity={0.4} />
    <circle cx={21.248} cy={23.904} r={1.173} fill="#fff" opacity={0.4} />
    <path
      fill="#1D1D1D"
      d="M18.6205 32.5304c-.7392 0-1.3384-.6388-1.3384-1.4268s.5992-1.4268 1.3384-1.4268c.7392 0 1.3385.6388 1.3385 1.4268s-.5993 1.4268-1.3385 1.4268ZM28.9965 32.5314c-.7392 0-1.3384-.6388-1.3384-1.4268 0-.7881.5992-1.4269 1.3384-1.4269.7392 0 1.3385.6388 1.3385 1.4269 0 .788-.5993 1.4268-1.3385 1.4268ZM26.9854 34.832c-.0168 1.7406-1.433 3.1464-3.1775 3.1464-1.7445 0-3.1607-1.4058-3.1775-3.1464h6.355Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M20.481 6.531c-.5459 2.3898.1696 6.6568 6.1173 6.5646.4255-2.4052-.4586-6.6938-6.1173-6.5646Z"
    />
  </svg>
);
