import type * as React from 'react';
export const Animal2Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    {...props}
  >
    <path fill="#FFEDCD" d="M0 0h48v48H0z" />
    <path
      fill="#FFF8EB"
      d="M43 48H5c0-11.598 8.5066-21 19-21s19 9.402 19 21Z"
    />
    <path
      fill="#1D1D1D"
      d="M43 48c0-11.598-8.5066-21-19-21S5 36.402 5 48h1.5095c0-10.9031 7.9651-19.4858 17.4905-19.4858 9.5255 0 17.4906 8.5827 17.4906 19.4858H43Z"
    />
    <path
      fill="#F7907E"
      d="M17.9825 14.2554c.0653-.849.4546-1.8392-.1117-2.6181-1.2282-1.7357-5.2417-3.2358-6.61-2.1102-1.3682 1.1257-.9209 4.9667.1814 6.824.5267.9155 2.0207.9944 2.995 1.109"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.9825 14.2554c.0653-.849.4546-1.8392-.1117-2.6181-1.2282-1.7357-5.2417-3.2358-6.61-2.1102-1.3682 1.1257-.9209 4.9667.1814 6.824.5267.9155 2.0207.9944 2.995 1.109"
    />
    <path
      fill="#F7907E"
      d="M29.375 14.7456c-.1314-.8413-.5968-1.798-.0931-2.6188 1.089-1.8262 4.9731-3.6352 6.4251-2.6198 1.4519 1.0154 1.3059 4.8796.3519 6.8173-.4536.9538-1.9369 1.1492-2.8992 1.3395"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M29.375 14.7456c-.1314-.8413-.5968-1.798-.0931-2.6188 1.089-1.8262 4.9731-3.6352 6.4251-2.6198 1.4519 1.0154 1.3059 4.8796.3519 6.8173-.4536.9538-1.9369 1.1492-2.8992 1.3395"
    />
    <path
      fill="#FBC0B5"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M36.25 23.5c0 6.4619-5.4565 11.75-12.25 11.75S11.75 29.9619 11.75 23.5 17.2065 11.75 24 11.75s12.25 5.2881 12.25 11.75Z"
    />
    <path
      fill="#1D1D1D"
      d="M27.8748 27.1976c-.0205 2.1227-1.7475 3.8371-3.875 3.8371s-3.8545-1.7144-3.8749-3.8371h7.7499Z"
    />
    <ellipse cx={17.08} cy={27.362} fill="#F7907E" rx={1.887} ry={1.754} />
    <ellipse cx={31.055} cy={27.362} fill="#F7907E" rx={1.887} ry={1.754} />
    <path stroke="#1D1D1D" strokeWidth={1.5} d="M22.75 35v13" />
    <circle cx={26.058} cy={38.497} r={1.251} fill="#1D1D1D" />
    <circle cx={26.058} cy={43.895} r={1.251} fill="#1D1D1D" />
    <path
      fill="#F7907E"
      stroke="#1D1D1D"
      strokeWidth={1.5}
      d="M35.8075 21.8843c-6.5383-5.8488-16.3114-5.9399-23.2387-2.5054.9503-4.0603 5.8923-8.6681 12.5053-8.1433 7.1984.5713 10.3872 5.8376 10.7334 10.6487Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.5845 22.7027c.2152.7492.9056 1.2973 1.724 1.2973s1.5088-.5481 1.724-1.2973M17.9675 22.7027c.2153.7492.9057 1.2973 1.7241 1.2973.8183 0 1.5087-.5481 1.724-1.2973"
    />
  </svg>
);
